import React from "react";
import TypeText from "./TypeText";
import aboutImage from "../../assets/images/about.jpg";
import develoiperImage from "../../assets/images/develope.jpg";
//import exprienceImage from "../../assets/images/exprince.jpg";
import {FaDownload, FaReact, FaHtml5, FaCss3Alt, FaBootstrap, FaNode, FaPhp, FaSass,FaAws, FaLess, FaAngular, FaJava, FaVuejs , FaGithub } from "react-icons/fa";
import { SiRedux, SiMongodb, SiGraphql, SiJavascript, SiJquery, SiTypescript, SiTailwindcss, SiWordpress, SiCakephp,SiMysql,
  SiJest, SiTestinglibrary,  SiAwslambda, SiCodeigniter, SiAmazonec2, SiGitlab, SiJira, SiBisecthosting} from "react-icons/si";
import {DiYii, DiMysql, DiScrum} from "react-icons/di"
import { TbSeo } from "react-icons/tb";
import { Tooltip } from 'react-tooltip';
import myResume from '../../Praveen-UI-Developer.pdf'
import { Link } from "react-router-dom";


const WebTechnologies = [
  { id:1, name: 'ReactJs', iconText: FaReact },
  { id:2, name: 'Javascript', iconText: SiJavascript },
  { id:3, name: 'HTML5', iconText: FaHtml5 },
  { id:4, name: 'Angular', iconText: FaAngular },
  { id:5, name: 'NodeJs', iconText: FaNode },
  { id:6, name: 'Redux', iconText: SiRedux },
  { id:7, name: 'CSS3', iconText: FaCss3Alt },
  { id:8, name: 'Bootstrap', iconText: FaBootstrap },
  { id:9, name: 'Tailwind', iconText: SiTailwindcss },
  { id:10, name: 'jQuery', iconText: SiJquery },
  { id:11, name: 'TypeScript',iconText: SiTypescript},
  { id:12, name: 'VueJs', iconText: FaVuejs },
  { id:13, name: 'SASS', iconText: FaSass },
  { id:14, name: 'LASS', iconText: FaLess },
  { id: 15, name: 'Graphql', iconText: SiGraphql },
  {id: 16,  name: 'PHP', iconText: FaPhp  },
  {id: 17,  name: 'CMS(WordPress)',  iconText: SiWordpress  },
  {id: 18,  name: 'Yii', iconText: DiYii  },
  {id: 19,  name: 'CakePHP', iconText: SiCakephp  },
  {id: 20,  name: 'Core Java', iconText: FaJava  },
  {id: 21,  name: 'Codeigniter', iconText: SiCodeigniter  },
  { id: 22, name: 'MySQL', iconText: SiMysql },
  { id: 23, name: 'SQL Server', iconText: DiMysql },
  { id: 24, name: 'MongoDB', iconText: SiMongodb },
  
]


const othertools =[
  { id: 1, name: 'Jest', iconText: SiJest  },
  { id: 2, name: 'Karma', iconText: SiTestinglibrary  },
  { id: 3, name: 'Hosting', iconText: SiBisecthosting  },
  { id: 4, name: 'GitHub', iconText: FaGithub  },
  { id: 5, name: 'GitLab', iconText: SiGitlab  },
  { id: 6, name: 'JIRA', iconText: SiJira  },
  { id: 7, name: 'Graphql', iconText: SiGraphql  },
  { id: 8, name: 'AWS, S3', iconText: FaAws  },
  { id: 9, name: 'EC2', iconText: SiAmazonec2  },
  { id: 10, name: 'Scrum', iconText: DiScrum  },
  { id: 11, name: 'Lambda', iconText: SiAwslambda  },
  { id: 12, name: 'SEO', iconText: TbSeo  }

  
]






function Aboutus() {
  return (
    <div className=" bg-white pt-24 sm:pt-32 dark:bg-black dark:text-white">
      <div className="flex mx-auto max-w-7xl px-6 lg:px-8 flex-col lg:flex-row relative ">
        <div className="flex-1 ">
          <div className="lg:max-w-lg">
            <h2 className="text-base font-semibold leading-7 text-green-600 ">
              My Self
            </h2>
            <p className="mt-2 text-3xl font-bold font-font-three  tracking-tight text-gray-900 dark:text-white sm:text-4xl">
              Praveen Kumar Bandi
            </p>

            <p className="mt-6 text-xl leading-8 font-bold  text-lime-700 dark:text-white">
              <TypeText />
            </p>
            <p>
              As a UI developer, I enjoy tackling new challenges and
              continuously expanding my skillset. I am proficient in ReactJS and
              PHP and Javascript, as well as have knowledge in programming
              languages such as Javascript, ReactJs, PHP, MySql, WordPress,
              HTML5, and CSS3, SASS, LASS. I have a passion for working with
              Node.js, MongoDB, and modern Javascript libraries and frameworks
              like React.js I am also interested in building new Web
              Technologies and Products, as well as exploring areas related to
              Artificial Intelligence.
            </p>
           
          </div>
          <div className=" text-left mt-4 mb-4">
         
          <Link to={myResume} target={"_blank"}>
            <button
            type="button"
            className=" bg-green-600 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:text-indigo-600"
          >
            Download Resume
            <FaDownload className="ml-1 mr-1.5 h-5 w-5 text-white" aria-hidden="true" />
            
          </button>
          </Link>
      
          </div>
        </div>
        <div className="flex-1 relative  ">
          <div
            className="bg-cover bg-center w-full h-auto "
            style={{
              backgroundImage: "url(" + aboutImage + ")",
            }}
          >
            <div className=" bg-black ">
              <img
                src={develoiperImage}
                alt="home pic"
                className="img-fluid home-page  w-80  lg:-mt-28 lg:-mr-12 lg:absolute md:absolute md:top-9 md:right-0 lg:top-9 lg:right-0 h-96"
              />
              <img
                src={aboutImage}
                alt="home pic"
                className="img-fluid home-page  w-full  h-90"
              />
            </div>
          </div>
        </div>
      </div>
      {/** Exprince  */}

      <div className=" bg-black pt-16 text-white sm:pt-16 dark:bg-black dark:text-white">
        <p className="mt-2 text-3xl font-bold tracking-tight text-center text-white dark:text-white sm:text-4xl">
          Technical <span className=" text-green-500"> Skills</span>
        </p>
        <div class="flex flex-wrap md:mx-16 md:mt-10 lg:mx-16 lg:mt-10">
                
              {WebTechnologies.length > 0 && WebTechnologies.map((webtech) => (
                <div className=" m-1 p-3 lg:p-5 md:p-5 xl:p-5">
                  <Link data-tooltip-id={"techId"+ webtech.id}> <webtech.iconText
                          className=" text-indigo-600 text-6xl"
                          aria-hidden="true" 
                        /> </Link>
                    
                    <Tooltip className="text-4xl inline-block p-10 m-10 tech-text"
                    id={"techId"+ webtech.id}
                    content={webtech.name} 
                    onMouseOver={['hover']}
                    />
                  </div>
              ))}
        </div>
        
      </div>
      <div className=" bg-black py-2  text-white sm:py-2 dark:bg-black dark:text-white">
        <p className="mt-2 text-3xl font-bold tracking-tight text-center text-white dark:text-white sm:text-4xl">
          <span className=" text-green-500"> Other Tools </span>& Services
        </p>
        
        <div class="flex flex-wrap md:mx-16 md:mt-10 lg:mx-16 lg:mt-10">
                
              {othertools.length > 0 && othertools.map((other) => (
                <div className="p-2 lg:p-5 md:p-5 xl:p-5">
                  <Link data-tooltip-id={"tool"+ other.id}> <other.iconText
                          className=" text-indigo-600 text-6xl "
                          aria-hidden="true" 
                        /> </Link>
                    
                    <Tooltip className="text-4xl inline-block p-10 m-10 bg-green-600 text-white tech-text"
                    id={"tool"+ other.id}
                    content={other.name} 
                    onMouseOver={['hover']}
                    />
                  </div>
              ))}
        </div>
        
      </div>
    </div>
  );
}
export default Aboutus;
