import React from "react";
import { NavLink } from "react-router-dom";
// import imagemMap from "../../assets/projects/comakeit.png";
import Images from '../Helpers/MyImage'

function Card(props) {
  const { id, title, company_name, description, imgUrl, liveUrl, type, skills } =
    props.projectData;

    
    //var description_length = [...description.toLowerCase()].filter(x => x === 't').length;





  return (
    <div key={id} className=" m-0 md:m-10 lg:m-10 xl:m-10 shadow-lg shadow-cyan-500/50 max-w-sm bg-white border relative border-gray-200 rounded-lg  dark:bg-gray-800 dark:border-gray-700">
      <div className="carde-image border-b-4 w-fit  border-green-500">
        {/* <img
          className="rounded-t-lg"
          src={window.location.origin + "/projects/" + imgUrl}
          alt={title}
        /> */}
         <Images imagetData={imgUrl} key={id} className=' h-56 ' />
      </div>
     



      <div className="grid place-content-center relative ">
        <NavLink to={liveUrl} target={"_blank"}>
          <div className="  btn-more  text-white p-5  -mt-8  place-content-center border-2 border-white   bg-green-400 rounded-full  ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-6 h-6  "
            >
              <path
                fillRule="evenodd"
                d="M12.207 2.232a.75.75 0 0 0 .025 1.06l4.146 3.958H6.375a5.375 5.375 0 0 0 0 10.75H9.25a.75.75 0 0 0 0-1.5H6.375a3.875 3.875 0 0 1 0-7.75h10.003l-4.146 3.957a.75.75 0 0 0 1.036 1.085l5.5-5.25a.75.75 0 0 0 0-1.085l-5.5-5.25a.75.75 0 0 0-1.06.025Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </NavLink>
      </div>
      <div className="p-5 card-title justify-center text-center ">
        <NavLink to={liveUrl}>
          <span className="text-center bg-pink-500 text-white p-1 rounded border-spacing-1 ">
            {company_name}
          </span>
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-center text-custom-blue  dark:text-white">
            {title}
            
          </h5>
        </NavLink>
        <p className="card-note  font-normal text-gray-700 dark:text-gray-400">
          
          {description}
          {description.length > 100 ? description.substring(0, 100) + '...' : description}
        </p>
      </div>

      <div className="card-tech pb-12 ml-4 mr-4 justify-center">
        {skills.map((sikil, index) => (
          <span className="inline-flex m-1 items-center  rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-custom-blue dark:bg-black dark:text-white ring-1 ring-inset ring-gray-500/10">
            {sikil.name}
          </span>
        ))}
      </div>
      <div className="flex flex-row text-center justify-center  absolute inset-x-0 bottom-0 p-0.5">
      <div className="basis-1/4 text-sm  bg-blue-500 text-white m-2 p-1 rounded border-spacing-1 ">
          {type}
        </div>
        <div className="basis-1/4  bg-pink-800 text-white text-sm p-1 m-2 rounded border-spacing-1">
          <NavLink to={liveUrl}>View</NavLink>
        </div>
        <div className="basis-1/4   bg-green-800 text-sm text-white m-2 p-1 rounded border-spacing-1">
          <NavLink to={liveUrl}>Live</NavLink>
        </div>
       
      </div>
    </div>
  );
}

export default Card;
